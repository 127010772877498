import {
  FormControl,
  Grid,
  Spinner,
  Text
} from "@chakra-ui/react";
import FieldApprovalCard from "../FieldApprovalCard";

function BankDetailsVerificationForm({ entity, details, isLoading, canVerify }) {
  //console.log(details);
  const { accountName, accountNumber, bankName, branchCode, branchName, company, ifscCode, bankingPartner, accountAddress, iban, swift, branchAddress, country } = details || {};

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {details && Object.keys(details).length > 0 ? (
            <>
              {entity == "indian" ? (
                <FormControl>
                  <Grid gridTemplateColumns={"repeat( auto-fill, minmax(320px, 1fr) )"} gap={"20px"} >

                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={accountName}
                      title='Account Name'
                    />
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={accountNumber}
                      name='accountNumber'
                      title='Account Number'
                    />

                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={bankName}
                      name='bankName'
                      title='Bank Name'
                    />
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={branchAddress}
                      title='Bank Address'
                    />

                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={branchName}
                      name='branchName'
                      title='Branch Name'
                    />
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={ifscCode}
                      name='ifscCode'
                      title='IFSC Code '
                    />

                  </Grid>
                </FormControl>
              ) : (
                <FormControl>
                  <Grid gridTemplateColumns={"repeat( auto-fill, minmax(320px, 1fr) )"} gap={"20px"} >

                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={bankingPartner}
                      title='Banking Partner'

                    />
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={accountName}
                      name='accountName'
                      title='Account Name'

                    />
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={accountNumber}
                      title='Beneficiary Account Number'

                    />
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={accountAddress}
                      name='accountAddress'
                      title='Beneficiary Address'

                    />
                    <>
                      {iban?.value ? (
                        <FieldApprovalCard
                          canVerify={canVerify}
                          for_={'bank'}
                          fieldData={iban}
                          title='IBAN'

                        />
                      ) : (
                        <FieldApprovalCard
                          canVerify={canVerify}
                          for_={'bank'}
                          fieldData={swift}
                          title='SWIFT/BIC'

                        />
                      )}
                    </>
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={bankName}
                      name='bankName'
                      title='Bank Name'

                    />
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={branchAddress}
                      title='Bank Address'

                    />
                    <FieldApprovalCard
                      canVerify={canVerify}
                      for_={'bank'}
                      fieldData={country}
                      title='Country'

                    />
                  </Grid>
                </FormControl>
              )}
            </>
          ) : (
            <Text>No data available</Text>
          )}
        </>
      )}
    </>
  );
}

export default BankDetailsVerificationForm;
