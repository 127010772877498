// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.sticky {
    overflow: scroll;
}
.sticky .header, .sticky .footer {
    position: sticky;
    z-index: 1;
    width: fit-content;
}
.sticky .header {
    top: 0;
    box-shadow: 0px 3px 3px #ccc;
}
.sticky .footer {
    bottom: 0;
    box-shadow: 0px -3px 3px #ccc;
}
.sticky .body {
    position: relative;
    z-index: 0;
}
.sticky [data-sticky-td] {
    position: sticky;
}
.sticky [data-sticky-last-left-td] {
    box-shadow: 2px 0px 3px #ccc;
}
.sticky [data-sticky-first-right-td] {
    box-shadow: -2px 0px 3px #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/appTable.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,MAAM;IACN,4BAA4B;AAChC;AACA;IACI,SAAS;IACT,6BAA6B;AACjC;AACA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,gBAAgB;AACpB;AACA;IACI,4BAA4B;AAChC;AACA;IACI,6BAA6B;AACjC","sourcesContent":["\n\n.sticky {\n    overflow: scroll;\n}\n.sticky .header, .sticky .footer {\n    position: sticky;\n    z-index: 1;\n    width: fit-content;\n}\n.sticky .header {\n    top: 0;\n    box-shadow: 0px 3px 3px #ccc;\n}\n.sticky .footer {\n    bottom: 0;\n    box-shadow: 0px -3px 3px #ccc;\n}\n.sticky .body {\n    position: relative;\n    z-index: 0;\n}\n.sticky [data-sticky-td] {\n    position: sticky;\n}\n.sticky [data-sticky-last-left-td] {\n    box-shadow: 2px 0px 3px #ccc;\n}\n.sticky [data-sticky-first-right-td] {\n    box-shadow: -2px 0px 3px #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
