import { array, boolean, number, object, string } from "yup";

const indianIFSCRegEx = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$");
const panCardRegEx = new RegExp("/^[A-Z]{5}[0-9]{4}[A-Z]$/");

const loginSchema = object({
  email: string().required("Required").email("Invalid email ID"),
  password: string().required("Required").min(8, "Password must be at least 8 characters"),
});

const signUpschema = object({
  firstName: string().required("Required"),
  lastName: string().required("Required"),
  email: string().required("Required").email("Invalid email ID"),
  password: string().required("Required").min(8, "Password must be at least 8 characters"),
  phoneNumber: string().matches(/^[0-9]*$/, 'Only numbers are allowed').required('Required'),
});

const commisionFormSchema = object({
  retroComission: number().typeError('Invalid').required("Required")
    .test('is-number', 'Must be a number', (value) => {
      return !isNaN(value);
    }),
  firstRetroPartnerName: string(),
  firstRetroPartnerCommission: number().typeError('Invalid')
  // .test('is-number', 'Must be a number', (value) => {
  //   return !isNaN(value);
  // }),
  ,
  secondRetroPartnerName: string(),
  secondRetroPartnerCommission: number()
});


const legalDetailsIndianFormSchema = object({
  panNumber: string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'Invalid').required("Required"),
  gstNumber: string().matches(
    ///^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/,
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z][0-9A-Z]{1}$/,
    'Incorrect format'
  ).required("Required"),
  termsCheck: boolean().oneOf([true], 'You must accept the terms'),
  confirmationCheck: boolean().oneOf([true], 'You must confirm'),
});

const legalDetailsNonIndianFormSchema = object({
  taxId: string().required("Required"),
  termsCheck: boolean().oneOf([true], 'You must accept the terms').required('Required'),
  confirmationCheck: boolean().oneOf([true], 'You must confirm').required('Required'),
});

const bankDetailsIndianFormSchema = object({
  bankName: string().required("Required"),
  accountName: string().required("Required"),
  accountNumber: string().required("Required"),
  ifscCode: string().required("Required").matches(indianIFSCRegEx, "Incorrect format"),
  branchName: string(),
  branchCode: string(),
  branchAddress: string().required("Required"),
});

const bankDetailsNonIndianFormSchema = object({
  isUsingOnlineBankingPartner: string(),
  bankingPartner: string().required("Required"),
  otherOnlineBankingPartner: string().when('bankingPartner', {
    is: 'Others',
    then: () => string().required("Required")
  }),
  accountName: string().required("Required"),
  accountNumber: string().required("Required"),
  accountType: string(),
  iban: string().when("accountType", {
    is: "iban",
    then: () => string().required("Required"),
  }),
  swift: string().when("accountType", {
    is: "swift",
    then: () => string().required("Required"),
  }),
  bankName: string().required("Required"),
  branchAddress: string().required("Required"),
  country: string().required("Required"),
  accountAddress: string().required("Required"),
});

const newAccountSchema = object({
  accountID: string().required("Required"),
});

const replyToRequestSchema = object({
  content: string().required("Required"),
  attachments: string(),
});

const requestSchema = object({
  adsAccount: string().required("Required"),
  requestType: string().required("Required"),
  description: string().required("Required"),
  attachments: string(),
});

const adAccountRequestSchema = object({
  companyName: string().required("Required"),
  description: string().required("Required"),
  landingPage: string().url('Invalid format'),
  creativeDriveLinks: string().url('Invalid format'),
  verifiedBmId: string().required("Required"),
  screenshotOfBusinessInfo: string(),
  accountTimezone: string().required("Required"),
  accountType: string().required("Required"),
  currency: string(),
  confirmationCheck: boolean().oneOf([true], 'You must confirm').required("Required"),
});

const editAdAccountRequestSchema = object({
  companyName: string().required("Required"),
  description: string().required("Required"),
  landingPage: string().url('Invalid format'),
  creativeDriveLinks: string().url('Invalid format'),
  verifiedBmId: string().required("Required"),
  screenshotOfBusinessInfo: string().required("Required"),
  accountTimezone: string().required("Required"),
  accountType: string().required("Required"),
  currency: string(),
  adsAccountId: string().required("Required"),
  adsAccountName: string().required("Required"),
  initialBalance: number().typeError('Must be a number')
});

const newCompanySchema = object({
  nickName: string().required("Required"),
  companyName: string().required("Required"),
  typeOfBussiness: string().required("Required"),
  companyType: string().required("Required"),
  registerAddress: string().required("Required").min(10, "Please enter minimum 10 characters"),
  correspondenceAddress: string().required("Required").min(10, "Please enter minimum 10 characters"),
  otherBusinessType: string(),
  websiteUrl: string().required("Required"),//.matches(URLRegEx, 'Invalid'),
  serviceDescription: string().required("Required"),
  representativeName: string().required("Required"),
  representativePhone: string().required("Required"),
  representativeEmail: string().required("Required"),
  representativeDesignation: string().required("Required"),
  ownerName: string(),
  ownerPhone: string(),
});

const newClientSchema = object({
  firstName: string().required("Required"),
  phoneNumber: string().matches(/^[0-9]*$/, 'Only numbers are allowed').required('Required'),
  lastName: string(),
  email: string().required("Required").email("Invalid email ID"),
  password: string().required("Required").min(8, "Password must be at least 8 characters"),
  role: string().required("Required"),
});

const assignClientSchema = object({
  clientId: string().required("Required"),
});

const editRequestSchema = object({
  checked: string().required("Required"),
  notes: string(),
});

const transferFormSchema = object({
  fromAdsAccount: string().required("Required"),
  toAdsAccount: string().required("Required"),
  amount: string().required("Required"),
});

const legalDetailsNonIndianVerificationSchema = object({
  registrationCertificate: object({
    isApproved: string(),
    reason: string().when("isApproved", {
      is: false,
      then: string().required("required"),
    }),
    notes: string(),
  }),
});

const legalDetailsIndianVerificationSchema = object({
  registrationCertificate: object({
    isApproved: string(),
    reason: string().when("isApproved", {
      is: false,
      then: string().required("required"),
    }),
    notes: string(),
  }),
});

const approvalCardSchema = object({
  reason: string(),
  isApproved: string(),
  notes: string(),
});

const sumOfpercentSplit = (values) => {
  const total = values?.reduce((acc, value) => acc + (value?.percent || 0), 0);
  // console.log(values);
  return total === 100;

};

const topUpRequestSchema = object({
  currency: string().required("required"),
  amount: string().required("required"),
  accountType: string(),
  paymentEntityName: string().required("required"),
  paymentProvider: string().required("required"),
  paymentProviderOther: string(),
  transactionId: string().required("required"),
  paymentReceipt: string().required("required"),
  transferredTo: string().required("required"),
  transferredFrom: string().required("required"),
  percentSplit: array().of(
    object().shape({
      percent: number()
        .min(0, 'Amount must be a positive number')
        .max(100, 'Amount cannot be more than 100')
    })
  ).test('sumOfRepeater', 'Percent split sum must be 100%', sumOfpercentSplit),
  amountReceived:
    number("Invalid")
      .positive("Must be a positive value").when(
        'amount',
        (amount, schema) => schema.max(amount, 'Amount received cannot be greater than client entered amount')
      )

});

const approveTopUpSchema = object({
  approve: boolean(),
  reply: string().when("approve", {
    is: false,
    then: () => string().required("required"),
  }),
  amountReceived: number().when("approve", {
    is: true,
    then: () => string().required("required"),
  }),
});

const confirmTopUpSchema = object({
  confirm: boolean().oneOf([true], 'required').required("required")
});

export {
  transferFormSchema,
  adAccountRequestSchema, approvalCardSchema, approveTopUpSchema, assignClientSchema, bankDetailsIndianFormSchema,
  bankDetailsNonIndianFormSchema, commisionFormSchema, confirmTopUpSchema, editAdAccountRequestSchema, editRequestSchema, legalDetailsIndianFormSchema, legalDetailsIndianVerificationSchema, legalDetailsNonIndianFormSchema, legalDetailsNonIndianVerificationSchema, loginSchema, newAccountSchema, newClientSchema, newCompanySchema, replyToRequestSchema, requestSchema, signUpschema, topUpRequestSchema
};

